
const NotFound = () => {
    return (
        <div className="home">
            <div className="home-content">
                <h1>Path Not Found</h1>
            </div>
        </div>
    )
}

export default NotFound;